// @ts-nocheck
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

interface FormatDateRelativeSignature {
  Args: {
    Positional: [Date];
    Named: {
      format?: string;
    };
  };
  Return: string;
}

export default class FormatDateRelativeHelper extends Helper<FormatDateRelativeSignature> {
  @service intl;

  constructor() {
    super(...arguments);
    this.intl.onLocaleChanged(this.recompute, this);
  }

  compute([value], { format }) {
    return this.intl.formatDateRelative(value, format);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'format/date-relative': typeof FormatDateRelativeHelper;
  }
}
