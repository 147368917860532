// @ts-nocheck
import Helper from '@ember/component/helper';
import { service, type Registry as Services } from '@ember/service';

import { CENTS_MULTIPLIER } from 'qonto/constants/transfers';

interface FormatMoneySignature {
  Args: {
    Positional: [number];
    // Named: {} -- Todo
  };
  Return: string;
}

interface FormatMoneyOptions {
  intl: IntlService;
  currency?: string;
  signus?: boolean | string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  displayUndefined?: boolean;
  undefinedPlaceholder?: string;
}

export function formatMoney(value: number, options: FormatMoneyOptions): string {
  const formatOptions = {
    minimumFractionDigits: 2,
    ...options,
  };
  const { minimumFractionDigits, maximumFractionDigits, displayUndefined, currency, intl } =
    formatOptions;
  const centMultiplier = CENTS_MULTIPLIER[currency];

  if (centMultiplier === 1) {
    formatOptions.minimumFractionDigits = options.minimumFractionDigits ?? 0;
    formatOptions.maximumFractionDigits =
      options.maximumFractionDigits ?? formatOptions.minimumFractionDigits;
  }

  const shouldSkipFormatting = !Number.isFinite(value) && displayUndefined;
  const undefinedPlaceholder = options.undefinedPlaceholder || '--';
  return shouldSkipFormatting ? undefinedPlaceholder : intl.formatMoney(value, formatOptions);
}

export default class FormatMoneyHelper extends Helper<FormatMoneySignature> {
  @service declare intl: Services['intl'];

  init() {
    super.init(...arguments);
    this.intl.onLocaleChanged(this.recompute, this);
  }

  compute([value]: [number], options: FormatMoneyOptions) {
    return formatMoney(value, {
      ...options,
      intl: this.intl,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'format/money': typeof FormatMoneyHelper;
  }
}
