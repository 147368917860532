// @ts-nocheck
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

/**
 * This helper can be used from the templates to send tracking events to
 * the `segment` service.
 *
 * ```hbs
 * <button {{on "click" (track "foo" bar=42)}}/>
 * ```
 */

interface TrackSignature {
  Args: {
    Positional: [string];
  };
  Return: () => void;
}

export default class TrackHelper extends Helper<TrackSignature> {
  @service segment;

  compute([event], params) {
    return () => {
      this.segment.track(event, params);
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    track: typeof TrackHelper;
  }
}
