// @ts-nocheck
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import { getCardShortName } from 'qonto/utils/cards/card-names';

interface DisplayCardShortNameSignature {
  Args: {
    Positional: [string];
  };
  Return: string;
}

export default class DisplayCardShortName extends Helper<DisplayCardShortNameSignature> {
  @service intl;

  compute([cardLevel]) {
    return getCardShortName(cardLevel, this.intl);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'display-card-shortname': typeof DisplayCardShortName;
  }
}
