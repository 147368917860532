// @ts-nocheck
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import { selectedCategoryList } from 'qonto/utils/cards/display-card-selected-categories';

interface DisplayCardSelectedCategoriesSignature {
  Args: {
    Positional: [string[]];
  };
  Return: string;
}

export default class DisplayCardSelectedCategories extends Helper<DisplayCardSelectedCategoriesSignature> {
  @service intl;

  compute([categoryTags]) {
    return selectedCategoryList(categoryTags, this.intl);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'display-card-selected-categories': typeof DisplayCardSelectedCategories;
  }
}
