// @ts-nocheck
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

interface TErrorSignature {
  Args: {
    Positional: [unknown];
  };
  Return: string;
}

export default class TErrorHelper extends Helper<TErrorSignature> {
  @service errors;
  @service intl;

  compute([error]) {
    return this.errors.messageForStatus(error);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    't-error': typeof TErrorHelper;
  }
}
