// @ts-nocheck
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import { activeDaysList } from 'qonto/utils/cards/display-card-active-days';

interface DisplayCardActiveDaysSignature {
  Args: {
    Positional: [string[]];
  };
  Return: string;
}

export default class DisplayCardActiveDays extends Helper<DisplayCardActiveDaysSignature> {
  @service intl;

  compute([activeDays]) {
    return activeDaysList(activeDays, this.intl);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'display-card-active-days': typeof DisplayCardActiveDays;
  }
}
