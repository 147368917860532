// @ts-nocheck
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import window from 'ember-window-mock';

interface TrackButtonSignature {
  Args: {
    Positional: [string];
  };
  Return: () => void;
}

export default class TrackButtonHelper extends Helper<TrackButtonSignature> {
  @service segment;

  compute([event]) {
    return () => {
      this.segment.track('navigation_button_clicked', {
        button_name: event,
        browser_width: `${window.innerWidth}px`,
        browser_height: `${window.innerHeight}px`,
      });
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'track-button': typeof TrackButtonHelper;
  }
}
