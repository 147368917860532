// @ts-nocheck
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import { IN_DISPUTE_STATUS } from 'qonto/constants/transactions';

export default class OperationTypeStatusHelper extends Helper {
  @service intl;

  compute([transaction]) {
    let operationTypeStatusMap = {
      biller: {
        credit: {
          completed: this.intl.t('transactions.operation-type.biller-in.completed'),
          pending: this.intl.t('transactions.operation-type.biller-in.processing'),
          reversed: this.intl.t('transactions.operation-type.biller-in.reverted'),
          canceled: this.intl.t('transactions.operation-type.biller-in.canceled'),
          declined: this.intl.t('transactions.operation-type.biller-in.declined'),
        },
        debit: {
          completed: this.intl.t('transactions.operation-type.biller-out.completed'),
          pending: this.intl.t('transactions.operation-type.biller-out.processing'),
          reversed: this.intl.t('transactions.operation-type.biller-out.reverted'),
          canceled: this.intl.t('transactions.operation-type.biller-out.canceled'),
          declined: this.intl.t('transactions.operation-type.biller-out.declined'),
        },
      },
      card: {
        completed: this.intl.t('transactions.operation-type.card.completed'),
        pending: this.intl.t('transactions.operation-type.card.processing'),
        reversed: this.intl.t('transactions.operation-type.card.reverted'),
        canceled: this.intl.t('transactions.operation-type.card.canceled'),
        declined: this.intl.t('transactions.operation-type.card.declined'),
        disputing: this.intl.t('transactions.operation-type.card.disputing'), // per se not a real transaction status
      },
      cheque: {
        pending: this.intl.t('transactions.operation-type.cheque.processing'),
        refunded: this.intl.t('transactions.operation-type.cheque.refunded'),
        canceled: this.intl.t('transactions.operation-type.cheque.canceled'),
        declined: this.intl.t('transactions.operation-type.cheque.declined'),
        received: this.intl.t('transactions.operation-type.cheque.processing'),
        validated: this.intl.t('transactions.operation-type.cheque.completed'),
        completed: this.intl.t('transactions.operation-types.cheque'),
      },
      direct_debit: {
        completed: this.intl.t('transactions.operation-type.direct-debit.completed'),
        pending: this.intl.t('transactions.operation-type.direct-debit.processing'),
        reversed: this.intl.t('transactions.operation-type.direct-debit.reverted'),
        canceled: this.intl.t('transactions.operation-type.direct-debit.canceled'),
        declined: this.intl.t('transactions.operation-type.direct-debit.declined'),
      },
      f24: {
        credit: {
          completed: this.intl.t('transactions.operation-type.f24-in.completed'),
          pending: this.intl.t('transactions.operation-type.f24-in.processing'),
          reversed: this.intl.t('transactions.operation-type.f24-in.reverted'),
          canceled: this.intl.t('transactions.operation-type.f24-in.canceled'),
          declined: this.intl.t('transactions.operation-type.f24-in.declined'),
        },
        debit: {
          completed: this.intl.t('transactions.operation-type.f24-out.completed'),
          pending: this.intl.t('transactions.operation-type.f24-out.processing'),
          reversed: this.intl.t('transactions.operation-type.f24-out.reverted'),
          canceled: this.intl.t('transactions.operation-type.f24-out.canceled'),
          declined: this.intl.t('transactions.operation-type.f24-out.declined'),
        },
      },
      f24_proxied: {
        completed: this.intl.t('transactions.operation-type.f24-proxied.completed'),
        pending: this.intl.t('transactions.operation-type.f24-proxied.processing'),
        reversed: this.intl.t('transactions.operation-type.f24-proxied.reverted'),
        canceled: this.intl.t('transactions.operation-type.f24-proxied.canceled'),
        declined: this.intl.t('transactions.operation-type.f24-proxied.declined'),
      },
      instant: {
        completed: this.intl.t('transactions.operation-type.instant.completed'),
        pending: this.intl.t('transactions.operation-type.instant.processing'),
        declined: this.intl.t('transactions.operation-type.instant.declined'),
      },
      nrc_payment: {
        completed: this.intl.t('transactions.operation-type.nrc-payment.completed'),
        declined: this.intl.t('transactions.operation-type.nrc-payment.declined'),
      },
      riba_payment: {
        completed: this.intl.t('transactions.operation-type.riba.completed'),
        declined: this.intl.t('transactions.operation-type.riba.declined'),
      },
      pagopa_payment: {
        completed: this.intl.t('transactions.operation-type.pagopa.completed'),
        pending: this.intl.t('transactions.operation-type.pagopa.processing'),
        declined: this.intl.t('transactions.operation-type.pagopa.declined'),
      },
      recall: {
        completed: this.intl.t('transactions.operation-type.recall.completed'),
        pending: this.intl.t('transactions.operation-type.recall.processing'),
      },
      seizure: {
        completed: this.intl.t('transactions.operation-type.seizure.completed'),
        pending: this.intl.t('transactions.operation-type.seizure.processing'),
        declined: this.intl.t('transactions.operation-type.seizure.declined'),
      },
      swift_income: {
        completed: this.intl.t('transactions.operation-type.swift-income.completed'),
        pending: this.intl.t('transactions.operation-type.swift-income.processing'),
        reversed: this.intl.t('transactions.operation-type.swift-income.reverted'),
        canceled: this.intl.t('transactions.operation-type.swift-income.canceled'),
        declined: this.intl.t('transactions.operation-type.swift-income.declined'),
      },
      transfer: {
        completed: this.intl.t('transactions.operation-type.transfer.completed'),
        pending: this.intl.t('transactions.operation-type.transfer.processing'),
        reversed: this.intl.t('transactions.operation-type.transfer.reverted'),
        canceled: this.intl.t('transactions.operation-type.transfer.canceled'),
        declined: this.intl.t('transactions.operation-type.transfer.declined'),
      },
      pay_later: {
        completed: this.intl.t('transactions.operation-type.pay-later.completed'),
        pending: this.intl.t('transactions.operation-type.pay-later.processing'),
        reversed: this.intl.t('transactions.operation-type.pay-later.reverted'),
        canceled: this.intl.t('transactions.operation-type.pay-later.canceled'),
        declined: this.intl.t('transactions.operation-type.pay-later.declined'),
      },
      financing_installment: {
        completed: this.intl.t('transactions.operation-type.pay-later.completed'),
      },
      direct_debit_collection: {
        credit: {
          completed: this.intl.t(
            'transactions.operation-type.direct_debit_collection.credit.completed'
          ),
          declined: this.intl.t(
            'transactions.operation-type.direct_debit_collection.credit.declined'
          ),
        },
        debit: {
          completed: this.intl.t(
            'transactions.operation-type.direct_debit_collection.debit.completed'
          ),
          declined: this.intl.t(
            'transactions.operation-type.direct_debit_collection.debit.declined'
          ),
          pending: this.intl.t('transactions.operation-type.direct_debit_collection.debit.pending'),
          returned: this.intl.t(
            'transactions.operation-type.direct_debit_collection.debit.returned'
          ),
        },
      },
      direct_debit_hold: {
        credit: {
          completed: this.intl.t('transactions.operation-type.direct_debit_hold.credit.completed'),
        },
        debit: {
          completed: this.intl.t('transactions.operation-type.direct_debit_hold.debit.completed'),
        },
      },
      other: {
        completed: this.intl.t('transactions.operation-types.unknown'),
      },
      card_acquirer_payout: {
        completed: this.intl.t('transactions.operation-type.card-acquirer-payout.completed'),
        pending: this.intl.t('transactions.operation-type.card-acquirer-payout.pending'),
      },
    };

    let {
      side,
      debit,
      status,
      subject,
      operationType,
      canceled,
      chargebackDisputingTypes,
      isCardTransaction,
      isInstantTransfer,
      isDirectDebit,
      isProxiedF24,
      isPagoPaPayment,
      isNrcPayment,
      isSeizure,
      isCheckTransaction,
      isPayLater,
      isSDDReturnedAfterSettlementDate,
      isTapToPay,
    } = transaction;

    if (canceled && operationType !== 'direct_debit_collection') {
      status = 'canceled';
    }

    if (isPayLater) {
      operationType = 'pay_later';
    }

    if (isDirectDebit) {
      operationType = 'direct_debit';
    }

    if (operationType === 'transfer' && debit && isInstantTransfer) {
      operationType = 'instant';
    }

    if (operationType === 'f24' && isProxiedF24) {
      operationType = 'f24_proxied';
    }

    if (isNrcPayment) {
      operationType = 'nrc_payment';
    }

    if (isPagoPaPayment) {
      operationType = 'pagopa_payment';
    }

    if (isSeizure) {
      operationType = 'seizure';
    }

    if (operationType === 'other') {
      operationType = 'other';
    }

    if (isCheckTransaction) {
      status = subject?.get('status') || status;
    }

    // specific case for Returned SDD collection
    if (isSDDReturnedAfterSettlementDate && side === 'debit') {
      return operationTypeStatusMap['direct_debit_collection']['debit']['returned'];
    }

    if (isCardTransaction) {
      let hasPendingChargebackClaim = chargebackDisputingTypes?.length > 0;
      if (hasPendingChargebackClaim) {
        // Chargeback claim status is not a real transaction status
        // but it's shown where the transaction status is
        status = IN_DISPUTE_STATUS;
      }
    }

    if (isTapToPay) {
      operationType = 'card_acquirer_payout';
    }

    return (
      operationTypeStatusMap[operationType]?.[side]?.[status] ||
      operationTypeStatusMap[operationType]?.[status] ||
      operationTypeStatusMap['transfer'][status]
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'operation-type-status': typeof OperationTypeStatusHelper;
  }
}
