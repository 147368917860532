// @ts-nocheck
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

interface ZendeskLocalizationSignature {
  Args: {
    Positional: [number];
  };
  Return: string;
}

export default class ZendeskLocalizationHelper extends Helper<ZendeskLocalizationSignature> {
  @service zendeskLocalization;

  compute(args) {
    let [articleId] = args;

    if (!articleId) {
      return this.zendeskLocalization.getLocalizedHelpCenter;
    } else {
      return this.zendeskLocalization.getLocalizedArticle(articleId);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'zendesk-localization': typeof ZendeskLocalizationHelper;
  }
}
