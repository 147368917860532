import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
import type { SafeString } from '@ember/template/-private/handlebars';

import { marked } from 'marked';

interface MarkdownSignature {
  Args: {
    Positional: [markdown?: string];
  };
  Return: SafeString;
}

export function markdown([
  markdown,
]: MarkdownSignature['Args']['Positional']): MarkdownSignature['Return'] {
  const html = marked.parse(markdown || '') as string;

  return htmlSafe(html);
}

export default helper(markdown);
