// @ts-nocheck
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

/**
 * Given an alpha 2 country code,
 * This helper will display the name of a country
 * in the current locale
 *
 * ```hbs
 * <span>{{format/country "IT"}}</span>
 * <span>{{format/country "FR" style="short"}}</span>
 * ```
 */

interface FormatCountrySignature {
  Args: {
    Positional: [string];
    Named: Intl.DisplayNamesOptions;
  };
  Return: string;
}

export default class FormatCountryHelper extends Helper<FormatCountrySignature> {
  @service intl;

  compute([countryCode], options) {
    return this.intl.formatCountry(countryCode, options);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'format/country': typeof FormatCountryHelper;
  }
}
