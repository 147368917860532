import { helper } from '@ember/component/helper';

import { importSync } from '@embroider/macros';

/**
 * Try to get asset from various sources:
 * 1. App's static directory
 * 2. @repo/visual-assets package
 */
function getAssetPath(assetPath: string): string {
  const normalizedPath = assetPath.replace(/^\//, '');

  try {
    const module = importSync(`/static/${normalizedPath}`) as { default: string };
    return module.default;
  } catch {
    // Continue trying other sources
  }

  try {
    // Check if it's an illustration
    if (normalizedPath.startsWith('illustrations/')) {
      // Extract just the filename
      const fileName = normalizedPath.replace(/^illustrations\//, '');
      const module = importSync(`@repo/visual-assets/illustrations/${fileName}`) as {
        default: string;
      };
      return module.default;
    }
  } catch {
    // Continue if not found
  }

  throw new Error(`Could not find asset for path: ${assetPath}`);
}

export function importAsset([assetPath]: [string]): string {
  return getAssetPath(assetPath);
}

export default helper(importAsset);
