// @ts-nocheck
/* eslint-disable @qonto/no-import-roles-constants */
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import type { ValueOf } from 'type-fest';

import { ROLES } from 'qonto/constants/membership';

interface LocalizeRoleSignature {
  Args: {
    Positional: [ValueOf<typeof ROLES>];
  };
  Return: string;
}

export default class LocalizeRoleHelper extends Helper<LocalizeRoleSignature> {
  @service intl;

  compute([role]) {
    let roles = {
      [ROLES.OWNER]: this.intl.t('roles.owner'),
      [ROLES.ADMIN]: this.intl.t('roles.admin'),
      [ROLES.MANAGER]: this.intl.t('roles.manager'),
      [ROLES.REPORTING]: this.intl.t('roles.reporting'),
      [ROLES.EMPLOYEE]: this.intl.t('roles.employee'),
    };

    return roles[role];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'localize-role': typeof LocalizeRoleHelper;
  }
}
